<template>
  <div v-if="!readonly" class="Manager">
    <ManagerEdit ref="managerEdit" :create-view="true"></ManagerEdit>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ManagerEdit from "./Manager/ManagerEdit.vue";
import { dispatchListOffices, dispatchListTeams } from "@/store/main/actions";
import { dispatchClearManager } from "@/store/manager/actions";
import { readIsManager } from "@/store/auth/getters";

@Component({
  components: {
    ManagerEdit,
  },
})
export default class Manager extends Vue {
  public async mounted() {
    window["analytics"]["page"]("/manage/managers/create");
    await dispatchClearManager(this.$store);
    await dispatchListOffices(this.$store);
    await dispatchListTeams(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }

  beforeRouteLeave(to, from, next) {
    if (this.$refs.managerEdit["edited"]()) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }

  public beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  }

  preventNav(event) {
    if (!this.$refs.managerEdit["edited"]()) {
      return;
    }
    event.preventDefault();
    event.returnValue = "";
  }
}
</script>

<style>
.Manager {
  background-color: var(--white);
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
