<template>
  <ValidationObserver
    ref="validation-observer"
    v-slot="{ handleSubmit }"
    class="ManagerEdit p-d-flex p-flex-column"
  >
    <form @submit.prevent="handleSubmit(confirmSave)" @keydown.enter.prevent="">
      <div class="p-d-flex top-edit-block">
        <div class="name-block" />
        <div v-if="createView" class="name-text">Create Manager</div>
        <div v-else class="name-text">
          {{ manager.first_name }}
          {{ manager.last_name }}
        </div>
        <InputSwitch
          v-model="active"
          :disabled="readonly"
          class="active-status-switch"
        />
      </div>
      <div class="p-fluid form-input-container">
        <div class="p-formgrid p-grid">
          <ValidatedInput label="First Name" rules="required">
            <InputText v-model="firstName" :readonly="readonly" type="text" />
          </ValidatedInput>
          <ValidatedInput label="Last Name" rules="required">
            <InputText v-model="lastName" :readonly="readonly" type="text" />
          </ValidatedInput>
          <div class="p-field p-col">
            <label for="nickname">Nickname</label>
            <InputText
              id="nickname"
              v-model="nickname"
              :readonly="readonly"
              type="text"
            />
          </div>
        </div>

        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="scope">Permission</label>
            <Dropdown
              id="scope"
              v-model="scope"
              :disabled="readonly"
              :options="scopeOptions"
              optionLabel="name"
              optionValue="value"
            />
          </div>
          <ValidatedInput label="Designation" rules="required">
            <InputText v-model="title" :readonly="readonly" type="text" />
          </ValidatedInput>
        </div>

        <div class="p-formgrid p-grid">
          <ValidatedInput label="Email" rules="required|email">
            <InputText v-model="email" :readonly="readonly" type="email" />
          </ValidatedInput>
          <div class="p-field p-col">
            <label for="phone-number">Phone Number</label>
            <vue-tel-input
              id="phone-number"
              v-model="phoneNumber"
              :dynamicPlaceholder="true"
              :enabledCountryCode="true"
              :readonly="readonly"
              :validCharactersOnly="true"
              mode="international"
            ></vue-tel-input>
          </div>
        </div>

        <div class="p-formgrid p-grid">
          <ValidatedInput label="Department" rules="required">
            <InputText v-model="department" :readonly="readonly" type="text" />
          </ValidatedInput>
          <div class="p-field p-col">
            <label for="team">Team</label>
            <Dropdown
              id="team"
              v-model="teamId"
              :showClear="true"
              :disabled="readonly"
              :options="teams"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <ValidatedInput label="Joined Date" rules="required">
            <DatePick
              format="YYYY-MM-DD"
              v-model="joinedDate"
              :editable="!readonly"
            ></DatePick>
          </ValidatedInput>
        </div>

        <div class="p-formgrid p-grid">
          <ValidatedInput label="Country (physical)" rules="required">
            <Dropdown
              v-model="location"
              :filter="true"
              :disabled="readonly"
              :options="countries"
              dataKey="name"
              optionLabel="name"
              optionValue="alpha2Code"
            />
          </ValidatedInput>
          <ValidatedInput label="Office" rules="required">
            <Dropdown
              v-model="officeId"
              :disabled="readonly"
              :options="offices"
              dataKey="id"
              optionLabel="name"
              optionValue="id"
            />
          </ValidatedInput>
          <ValidatedInput label="P&L Office" rules="required">
            <Dropdown
              v-model="plOfficeId"
              :disabled="readonly"
              :options="offices"
              dataKey="id"
              optionLabel="name"
              optionValue="id"
            />
          </ValidatedInput>
        </div>

        <div class="p-formgrid p-grid">
          <ValidatedInput label="Dashboard ID" rules="integer|min_value:0">
            <InputText
              v-model="dashboardId"
              :readonly="readonly"
              type="number"
            />
          </ValidatedInput>
          <ValidatedInput label="Bamboo ID" rules="integer|min_value:0">
            <InputText v-model="bambooId" :readonly="readonly" type="number" />
          </ValidatedInput>
        </div>

        <div class="p-d-flex p-jc-between form-button-group">
          <div class="p-ml-2">
            <RequiredMark></RequiredMark>
            - required field
          </div>
          <div class="p-d-flex">
            <div v-if="isAlpha">
              <Button
                v-if="createView"
                icon="pi pi-trash"
                label="Cancel"
                class="p-button-danger p-button custom-button p-button-rounded custom-button-right-margin"
                @click="goBack()"
              />
              <Button
                v-if="!createView"
                :icon="deleting ? 'pi pi-spin pi-spinner' : 'pi pi-trash'"
                :disabled="deleting"
                label="Delete"
                class="p-button-danger p-button custom-button p-button-rounded custom-button-right-margin"
                @click="confirmDelete"
              />
              <Button
                :icon="saving ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
                :disabled="saving"
                label="Save"
                type="submit"
                class="p-button-main p-button custom-button p-button-rounded custom-button-right-margin"
                @click="remindRequired"
              />
            </div>
            <Button
              v-if="!createView"
              :icon="exporting ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
              :disabled="exporting"
              label="Export"
              @click="doExport"
              class="p-button-main p-button custom-button p-button-rounded"
            />
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { readManager } from "@/store/manager/getters";
import _ from "lodash";
import { intersect } from "@/utils";
import { readCountries, readOffices, readTeams } from "@/store/main/getters";
import {
  dispatchCreateManager,
  dispatchDeleteManager,
  dispatchUpdateManager,
} from "@/store/manager/actions";
import { api } from "@/api";
import { readIsAlpha } from "@/store/auth/getters";

const BLOCKED_EMAILS = [
  "contact@collabasia.co",
  "marketing@collabasia.co",
  "brands@collabasia.co",
];

@Component
export default class ManagerEdit extends Vue {
  public deleting = false;
  public exporting = false;
  public saving = false;

  @Prop({ default: false }) public readonly createView!: boolean;

  public deleteFailed = false;
  public scopeOptions = [
    { name: "User", value: "user" },
    { name: "Manager", value: "manager" },
    { name: "Super Admin", value: "super" },
    { name: "Alpha", value: "alpha" },
  ];

  public active: boolean = true;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public nickname: string | null = null;
  public scope: string | null = "User";
  public title: string | null = null;
  public email: string | null = null;
  public phoneNumber: string | null = null;
  public department: string | null = null;
  public teamId: number | null = null;
  public joinedDate: Date | null = null;
  public location: string | null = null;
  public officeId: number | null = null;
  public plOfficeId: number | null = null;
  public dashboardId: number | null | string = null;
  public bambooId: number | null | string = null;

  get manager() {
    return readManager(this.$store);
  }

  get offices() {
    return readOffices(this.$store);
  }

  get countries() {
    return readCountries(this.$store);
  }

  get teams() {
    return readTeams(this.$store);
  }

  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  get readonly() {
    return !this.isAlpha;
  }

  @Watch("joinedDate")
  public updateJoinedDate() {
    if (!this.joinedDate) {
      this.joinedDate = null;
    }
  }

  public goBack() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
  }

  public reset() {
    if (this.manager) {
      this.active = this.manager.active;
      this.firstName = this.manager.first_name;
      this.lastName = this.manager.last_name;
      this.nickname = this.manager.nickname || null;
      this.scope = this.manager.scope || "User";
      this.title = this.manager.title || null;
      this.email = this.manager.email || null;
      this.phoneNumber = this.manager.phone_number || null;
      this.department = this.manager.department || null;
      this.teamId = this.manager.team_id || null;
      this.joinedDate = this.manager.joined_date || null;
      this.location = this.manager.location || null;
      this.officeId = this.manager.office_id;
      this.plOfficeId = this.manager.pl_office_id || null;
      this.dashboardId = this.manager.dashboard_id || null;
      this.bambooId = this.manager.bamboo_id || null;
    }
  }

  public managerForm() {
    return {
      active: this.active,
      first_name: this.firstName,
      last_name: this.lastName,
      nickname: this.nickname,
      scope: this.scope.toLowerCase(),
      title: this.title,
      email: (this.email || "").toLowerCase(),
      phone_number: this.phoneNumber,
      department: this.department,
      team_id: this.teamId,
      joined_date: this.joinedDate,
      location: this.location,
      office_id: this.officeId,
      pl_office_id: this.plOfficeId,
      dashboard_id: this.dashboardId !== "" ? this.dashboardId : null,
      bamboo_id: this.bambooId !== "" ? this.bambooId : null,
    };
  }

  public edited() {
    const managerFormObject = this.managerForm();
    const commonKeys = intersect(managerFormObject, this.manager);
    let managerFiltered = {},
      key;
    for (key in this.manager) {
      if (this.manager.hasOwnProperty(key) && commonKeys.indexOf(key) != -1) {
        managerFiltered[key] = this.manager[key];
      }
    }
    return !_.isEqual(managerFiltered, managerFormObject);
  }

  public async confirmDelete() {
    const t = this;
    this["$dialog"]
      .confirm(
        "Are you sure you want to delete this manager?",
        this["$deleteOptions"]
      )
      .then(async function () {
        t.deleting = true;
        const response = await dispatchDeleteManager(t.$store, {
          id: t.manager.id,
        });
        if (response && response.status === 200) {
          await t.$router.replace(`/manage/managers`);
          t["$toast"].add({
            severity: "success",
            summary: "Manager successfully deleted.",
            life: 3000,
          });
        } else {
          t["$toast"].add({
            severity: "error",
            summary: "Could not delete manager",
            detail:
              "Make sure that the creator is not associated with any accounts before deleting.",
            life: 6000,
          });
        }
        t.deleting = false;
      });
  }

  public confirmSave() {
    const t = this;
    this["$dialog"]
      .confirm(
        "Are you sure you want to save the changes?",
        this["$saveOptions"]
      )
      .then(async function () {
        if (BLOCKED_EMAILS.includes(t.email) && t.active) {
          t["$toast"].add({
            severity: "error",
            detail: "Invalid email address.",
            life: 6000,
          });
          return;
        }

        t.saving = true;
        if (t.createView) {
          const response = await dispatchCreateManager(t.$store, {
            data: t.managerForm(),
          });
          if (response) {
            const newId = response.id;
            await t.$router.push(`/manage/managers/${newId}`);
            t["$toast"].add({
              severity: "success",
              detail: "Manager successfully added.",
              life: 3000,
            });
          } else {
            // t.$router.go(-1);
            t["$toast"].add({
              severity: "error",
              summary: "Could not create manager",
              detail: "Something went wrong",
              life: 6000,
            });
          }
        } else {
          const response = await dispatchUpdateManager(t.$store, {
            id: t.manager.id,
            data: t.managerForm(),
          });
          if (response) {
            t["$toast"].add({
              severity: "success",
              detail: "Manager successfully updated.",
              life: 3000,
            });
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not update manager",
              detail: "Something went wrong",
              life: 6000,
            });
          }
        }
        t.saving = false;
      });
  }

  public remindRequired() {
    const failed = this.$refs["validation-observer"]["flags"]["failed"];
    if (failed) {
      this["$toast"].add({
        severity: "error",
        summary: "Form not valid",
        detail:
          "Make sure to fill out required fields and follow the correct formats",
        life: 5000,
      });
    }
  }

  public async doExport() {
    this.exporting = true;
    const response = await api.exportPage("managers", [this.manager.id]);
    if (response) {
      let link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(response.data)
      );
      link.setAttribute("download", "export.csv");
      link.click();
      this["$toast"].add({
        severity: "success",
        summary: "File exported!",
        detail:
          "Import to Google Sheets or open with a text editor for optimal operations",
        life: 3000,
      });
    } else {
      this["$toast"].add({
        severity: "error",
        summary: "Something went wrong...",
        detail: "Could not export your file",
        life: 3000,
      });
    }
    this.exporting = false;
  }
}
</script>

<style>
.ManagerEdit {
  width: 50%;
}
</style>
